import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import smoothscroll from 'smoothscroll-polyfill';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Splash from '../components/page/Homepage/Splash';
import About from '../components/page/Homepage/About';
import Customers from '../components/page/Homepage/Customers';
import ExpertiseAreas from '../components/page/Homepage/ExpertiseAreas';
import ArticleGrid from '../components/page/Article/ArticleGrid';
import WorkGrid from '../components/work/WorkGrid';

const IndexPage = ({ data }) => {
  // Data
  const { prismicHomepage, allPrismicArticle, allPrismicWork } = data;

  // Scroll down
  const isClient = typeof window !== 'undefined';
  isClient && smoothscroll.polyfill();

  // Refs
  const scrollRef = useRef();

  // Action
  const scrollToRef = () => scrollRef.current.scrollIntoView({ 
    behavior: 'smooth', 
    block: 'start' 
  });

  // Body
  const body = prismicHomepage.data.body.map((slice, index) => {
    switch (slice.slice_type) {
      case ('image_gallery'):
        return <Customers key={'slice-' + index} slice={slice} scrollRef={scrollRef} />;
      case ('text'):
        return <About key={'slice-' + index} slice={slice} />;
      case ('expertise'):
        return <ExpertiseAreas key={'slice-' + index} slice={slice} />
      case ('articles'):
        return (
          <ArticleGrid 
            key={'slice-' + index} 
            articles={allPrismicArticle.nodes}
            title={slice.primary.article_section_title}
            bgColor small
          />
        );
      case ('work'):
        return (
          <WorkGrid 
            key={'slice-' + index} 
            work={allPrismicWork.nodes}
            title={slice.primary.work_section_title}
            buttonTitle={slice.primary.work_button_title}
            buttonLink={slice.primary.work_button_link}
            limit={4}
          />
        );
      default:
        return null;
    }
  });

  return (
    <Layout transparent>
      <SEO
        noTemplate
        title={prismicHomepage.data.meta_title}
        description={prismicHomepage.data.meta_description}
        type='website'
      />
      <Splash 
        image={prismicHomepage.data.title_image} 
        title={prismicHomepage.data.title}
        text={prismicHomepage.data.description}
        buttonTitle={prismicHomepage.data.call_to_action_title}
        buttonLink={prismicHomepage.data.call_to_action_link}
        scrollTo={scrollToRef}
      />
      {body}
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    prismicHomepage {
      data {
        body {
          ... on PrismicHomepageBodyArticles {
            slice_type
            primary {
              article_section_title {
                raw
              }
            }
          }
          ... on PrismicHomepageBodyWork {
            slice_type
            primary {
              work_section_title {
                raw
              }
              work_button_link {
                uid
                type
                lang
              }
              work_button_title
            }
          }
          ... on PrismicHomepageBodyExpertise {
            id
            slice_type
            items {
              expertise_icon {
                alt
                fluid(maxWidth: 800) {
                  ...GatsbyPrismicImageFluid
                }
              }
              expertise_text {
                raw
              }
              expertise_title {
                raw
              }
            }
            primary {
              expertise_color
              expertise_title {
                raw
              }
            }
          }
          ... on PrismicHomepageBodyText {
            id
            slice_type
            primary {
              text_button_link {
                uid
                type
                lang
              }
              text_button_title {
                raw
              }
              text_content {
                raw
              }
              text_title {
                raw
              }
            }
          }
          ... on PrismicHomepageBodyImageGallery {
            slice_type
            items {
              customer_logo_image {
                alt
                fluid(maxWidth: 800) {
                  ...GatsbyPrismicImageFluid
                }
              }
              customer_logo_image_inverse {
                alt
                fluid(maxWidth: 800) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
        description {
          raw
        }
        meta_description
        meta_title
        title {
          raw
        }
        title_image {
          alt
          fluid(maxWidth: 1920) {
            ...GatsbyPrismicImageFluid
          }
        }
        call_to_action_title {
          raw
        }
        call_to_action_link {
          uid
          type
          lang
        }
      }
    }
    allPrismicWork(sort: {fields: data___date, order: DESC}) {
      nodes {
        data {
          title {
            raw
          }
          featured_image {
            alt
            fluid(maxWidth: 800) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        uid
        type
        lang
      }
    }
    allPrismicArticle(sort: {order: DESC, fields: last_publication_date}) {
      nodes {
        data {
          title {
            raw
          }
          featured_image {
            alt
            fluid(maxWidth: 800) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        uid
        type
        lang
      }
    }
  }
`;
