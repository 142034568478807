import React from 'react';
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../../../../utils/linkResolver';

import {
  Title,
  SplashImage, 
  Overlay, 
  Container, 
  Description, 
  ArrowDown,
  ArrowDownButton
} from './styles';

import Button from '../../../UI/Button';
import useWidth from '../../../../hooks/useWidth';
import ArrowIcon from '../../../../assets/icons/arrow-down.svg';

const Splash = ({ image, title, text, scrollTo, buttonTitle, buttonLink }) => {
  const width = useWidth();
  const mobile = Boolean(width < 960);
  const style = !mobile ? { backgroundAttachment: 'fixed' } : null;
  return (
    <SplashImage Tag="section" fluid={image && image.fluid} style={style}>
      <Overlay>
        <Container>
          <Title>
            {RichText.asText(title.raw)}
          </Title>
          <Description>
            {RichText.asText(text.raw)}
          </Description>
          <div style={{ textAlign: 'center' }}>
          <Button 
            text={RichText.asText(buttonTitle.raw)} 
            link={linkResolver(buttonLink)}
            border
          />
          </div>
        </Container>
        <ArrowDownButton onClick={scrollTo}>
          <ArrowDown src={ArrowIcon} alt="Scroll Down" />
        </ArrowDownButton>
      </Overlay>
    </SplashImage>
  );
};

export default Splash;