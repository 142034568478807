import styled from '@emotion/styled';
import { breakpoints } from '../../../../utils/styles';

export const Section = styled.section`
  background: ${({ theme }) => theme.primary};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 64px 24px;

  @media (min-width: ${breakpoints.xl}px){
    padding: 92px 32px;
  }

  @media (max-width: ${breakpoints.s}px){
    align-items: flex-start;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: ${breakpoints.s}px){
    align-items: center;
    flex-direction: column;
  }

  @media (min-width: ${breakpoints.xl}px){
    max-width: 1500px
  }
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.textLight};
`;