import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import BackgroundImage from 'gatsby-background-image';
import { breakpoints } from '../../../../utils/styles';

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-24px);
  }
  60% {
    transform: translateY(-12px);
  }
`;

export const SplashImage = styled(BackgroundImage)`
  height: 800px;

  @media (max-width: ${breakpoints.l}px){
    height: 90vh;
  }
`;

export const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 800px;
  background: linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,.3));

  @media (max-width: ${breakpoints.l}px){
    height: 90vh;
  }
`;

export const Container = styled.div`
  color: ${({ theme }) => theme.textLight};
  width: 100%;
  max-width: 850px;
  padding: 24px;

  @media (max-width: ${breakpoints.xl}px){
    max-width: 650px;
  }
`;

export const Title = styled.h1`
  @media (min-width: ${breakpoints.m}px){
    font-size: 48px;
  }

  @media (min-width: ${breakpoints.xl}px){
    font-size: 64px;
  }
`;

export const Description = styled.p`
  @media (min-width: ${breakpoints.xl}px){
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;

export const ArrowDownButton = styled.button`
  cursor: pointer;
  outline: none;
  border: none;
  background: none; 
  position: absolute;
  padding: 16px;
  bottom: 0;
  margin-bottom: 16px;
`;

export const ArrowDown = styled.img`
  margin-bottom: 0;
  animation: ${bounce} 2s 2;
`;