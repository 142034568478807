import React, { useContext } from 'react';
import { Section, Container, Logo } from './styles';
import ThemeContext from '../../../../context/ThemeContext';

const Customers = ({ slice, scrollRef }) => {
  // Theme
  const { isDark } = useContext(ThemeContext);
  return (
    <Section ref={scrollRef}>
      <Container>
        {slice.items.map(({ customer_logo_image, customer_logo_image_inverse }, index) => (
          <Logo
            key={`customer-${index}`}
            data-sal='slide-up'
            data-sal-easing='ease' 
            data-sal-duration='600'
            src={isDark ? customer_logo_image_inverse.fluid.src : customer_logo_image.fluid.src}
            alt={isDark ? customer_logo_image_inverse.alt : customer_logo_image.alt}
          />
        ))}
      </Container>
    </Section>
  );
};

export default Customers;