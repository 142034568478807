import styled from '@emotion/styled';
import { breakpoints, dimensions } from '../../../../utils/styles';

export const Section = styled.section`
  background: ${({ theme }) => theme.light};
  padding: 48px 32px;

  @media (max-width: ${breakpoints.l}px){
    padding: 24px;
  }
`;

export const Container = styled.div`
  max-width: ${dimensions.contentWidth};
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  @media (max-width: ${breakpoints.s}px){
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    flex-wrap: none;
    justify-items: center;
    align-items: center;
    gap: 16px;
  }
`;

export const Logo = styled.img`
  flex-basis: 16%;
  max-width: 230px;
  max-height: 64px;
  margin: 48px 24px;

  @media (max-width: ${breakpoints.xl}px){
    max-width: 200px;
    max-height: 48px;
    margin: 24px;
  }

  @media (max-width: ${breakpoints.l}px){
    max-width: 180px;
  }

  @media (max-width: ${breakpoints.s}px){
    width: 100%;
    max-height: 42px;
    margin: 16px 0;
  }
`;