import React from 'react';
import { RichText } from 'prismic-reactjs';
import { Section, Container } from './styles';
import { linkResolver } from '../../../../utils/linkResolver';
import Button from '../../../UI/Button';

const About = ({ slice }) => (
  <Section>
    <Container data-sal='slide-up' data-sal-easing='ease' data-sal-duration='600'>
      <h2>{RichText.asText(slice.primary.text_title.raw)}</h2>
      <p>{RichText.asText(slice.primary.text_content.raw)}</p>
      <Button 
        text={RichText.asText(slice.primary.text_button_title.raw)} 
        link={linkResolver(slice.primary.text_button_link)} 
      />
    </Container>
  </Section>
);

export default About;