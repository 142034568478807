import React from 'react';
import { RichText } from 'prismic-reactjs';
import { Container, IconContainer, TextContainer, Icon, Title, Text } from './styles';

const ExpertiseArea = ({ area }) => {
  return (
    <Container
      data-sal='slide-up'
      data-sal-easing='ease' 
      data-sal-duration='600'
    >
      <IconContainer>
        <Icon
          src={area.expertise_icon.fluid.src} 
          alt={area.expertise_icon.alt || 'area of expertise'} 
        />
      </IconContainer>
      <TextContainer>
        <Title>
          {RichText.asText(area.expertise_title.raw)}
        </Title>
        <Text>
          {RichText.asText(area.expertise_text.raw)}
        </Text>
      </TextContainer>
    </Container>
  );
};

export default ExpertiseArea;