import styled from '@emotion/styled';
import { breakpoints } from '../../../../utils/styles';

// Wrapper
export const Section = styled.section`
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.textDark};
  display: flex;
  justify-content: center;
  padding: 64px 24px;

  @media (min-width: ${breakpoints.xl}px){
    padding: 92px 32px;
  }
`;

// Container
export const Container = styled.div`
  max-width: 850px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: ${breakpoints.xl}px){
    max-width: 800px;
  }

  @media (max-width: ${breakpoints.s}px){
    align-items: flex-start;
  }
`;