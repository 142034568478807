import React from 'react';
import { RichText } from 'prismic-reactjs';
import { Section, Container, Title } from './styles';

import ExpertiseArea from './ExpertiseArea';

const Areas = ({ slice }) => (
  <Section>
    <Title
      data-sal='slide-up'
      data-sal-easing='ease' 
      data-sal-duration='600'
    >
      {RichText.asText(slice.primary.expertise_title.raw)}
    </Title>
    <Container>
      {slice.items.map((area, i) => (
        <ExpertiseArea key={`area-${i}`} area={area} />
      ))}
    </Container>
  </Section>
);

export default Areas;