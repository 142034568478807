import styled from '@emotion/styled';
import { breakpoints } from '../../../../../utils/styles';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  max-width: 300px;

  @media (max-width: ${breakpoints.l}px){
    padding: 32px 0;
  }

  @media (min-width: ${breakpoints.xl}px){
    max-width: 340px;
  }

  @media (max-width: ${breakpoints.s}px){
    flex-direction: row;
    max-width: 100%;
  }
`;

export const IconContainer = styled.div`
  min-width: 64px;
  min-height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoints.s}px){
    margin-left: 24px;
  }
`;

export const Icon = styled.img`
  margin-bottom: 32px;

  @media (max-width: ${breakpoints.s}px){
    margin: 0;
    width: 48px;
    height: 48px;
  }
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.textLight};
  margin-bottom: 8px;
  text-align: center;

  @media (min-width: ${breakpoints.xl}px){
    margin-bottom: 12px;
  }

  @media (max-width: ${breakpoints.s}px){
    text-align: left;
  }
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.textLight};
  text-align: center;
  margin: 0;

  @media (max-width: ${breakpoints.s}px){
    text-align: left;
  }
`;